import axios from 'axios'
import {baseUrl} from '@/utils/config'

class HttpRequest {
  constructor() {
    this.baseURL = baseUrl
    this.timeout = 3000
  }
  setInterceptors(instance) {
    instance.interceptors.request.use(config => {
      return config
    })
    instance.interceptors.response.use(res => {
      if (res.status === 200) {
        if (res.data) {
          return Promise.resolve(res.data)
        } else {
          return Promise.reject(res)
        }
      } else {
        return Promise.reject(res)
      }
    }, err => {
      switch (err.response.status) {
        case '401':
          console.log(err)
          break;
        default:
          break;
      }
      return Promise.reject(err)
    })
  }
  mergeOptions(options) {
    return { baseURL: this.baseURL, timeout: this.timeout, ...options }
  }
  request(options) {
    // 每次创建实例可以有自己单独的拦截器
    const instance = axios.create()
    this.setInterceptors(instance)
    const opts = this.mergeOptions(options)
    return instance(opts)
  }
  get(url, config) {
    return this.request({
      method: 'get',
      url,
      ...config
    })
  }
  post(url, data = {}) {
    return this.request({
      method: 'post',
      url,
      data
    })
  }
}

export default new HttpRequest