<template>
  <div class="main">
    <div class="banner">
      <banner></banner>
    </div>
    <supplyChain></supplyChain>
    <bigData></bigData>
    <solution></solution>
    <!-- <advantage></advantage> -->
    <partner></partner>
    <statistics ref='statistics'></statistics>
    <SignButton></SignButton>
  </div>
</template>

<script>
import SignButton from '@/components/signButton'
import Banner from './banner'
import SupplyChain from './supplyChain'
import BigData from './bigData'
import Solution from './solution'
import Advantage from './advantage'
import Partner from './partner'
import Statistics from './statistics'
const FROM_STATISTICS_TO_TOP = window.screen.availHeight
export default {
  // metaInfo: {
  //   meta: [
  //     {
  //       keywords: '仟金顶，SaaS，企业服务，大数据，项目管理，erp，crm，营销，供应链，招投标，工商查询，风险查询，电子合同',
  //       description: '仟金顶是专业的企业SaaS服务提供商，深耕泛建材行业，基于各垂直领域行业特性，介入供应链上下游真实贸易场景，以SaaS平台为载体，为产业链核心企业及其下游小微企业用户提供数字化营销和供应链管理服务，助力企业数字化转型，赋能小微企业，推动产业升级。'
  //     }
  //   ]
  // },
  components: {
    SignButton,
    Banner,
    SupplyChain,
    BigData,
    Solution,
    Advantage,
    Partner,
    Statistics
  },
  data() {
    return {
     
    }
  },
  mounted() {
    const statistics = this.$refs.statistics
    const el = statistics.$el
    window.addEventListener('scroll', () => this.countTo(statistics, el))
  },
  destroyed() {
    window.removeEventListener('scroll', () => this.countTo())
  },
  methods: {
    countTo(statistics, el) {
      let top = el.getBoundingClientRect().top
      if (top <= FROM_STATISTICS_TO_TOP) {
        statistics.start()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins.scss";
.main{
  position: relative;
}
</style>