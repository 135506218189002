<template>
  <div class="cooperate-partner">
    <Form @submit="onSubmit" class="form-area">
      <div class="title">合作申请</div>
      <div class="subtitle">提交后我们将帮您匹配合适的产品方案</div>
      <Field v-model="brandName" placeholder="请输入您的品牌名称" :rules="rules.brandName" />
      <Field v-model="companyName" placeholder="请输入您的公司名称" :rules="rules.companyName" />
      <Field v-model="username" placeholder="请输入您的姓名" :rules="rules.username" />
      <Field v-model="phone" type="tel" placeholder="请输入您的联系方式" :rules="rules.phone" :class="{'van-field--error' : isErr}"/>
      <Button block native-type="submit" :loading='loading' class="submit-btn">立即提交 获得服务</Button>
    </Form>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import http from '@/utils/request'
import { Form, Field, Button, Notify } from 'vant';
export default {
  components: {
    Form, Field, Button
  },
  setup() {
    const brandName = ref('')
    const companyName = ref('')
    const username = ref('')
    const phone = ref('')
    const isErr = ref(false)
    const loading = ref(false)
    const validator = (val) => {
      return /^1([3|4|5|7|8|])\d{9}$/.test(val);
    }
    const rules = {
      brandName: [{ required: true }],
      companyName: [{ required: true }],
      username: [{ required: true }],
      phone: [{ required: true }],
    }

    const onSubmit = async () => {
      if (!/^1([3|4|5|7|8|])\d{9}$/.test(phone.value)) {
        isErr.value = true
        Notify({ type: 'danger', message: '请输入正确的手机号码' });
        return false
      } else {
        isErr.value = false
      }
      loading.value = true
      const data = {
        brandName: brandName.value,
        companyName: companyName.value,
        name: username.value,
        phone: phone.value,
        sourceType: 0
      }
      let formData = new FormData()
      for(let key in data){
          formData.append(key, data[key])
      }
      const res = await http.post('/oc/occa/addCooperationApplication', formData)
      loading.value = false
      if (res.code === '0') {
        Notify({ type: 'success', message: '提交成功，我们会在3个工作日内联系您' });
      }
    }

    return {
      loading,
      brandName,
      companyName,
      username,
      phone,
      rules,
      isErr,
      onSubmit
    }
  }
}
</script>

<style lang="scss">
.cooperate-partner{
  padding: 68px 38px 100px;
  background: url('../../../assets/images/partner/coop-bg.png');
  background-size: 100% auto;
  .form-area{
    padding: 86px 86px 74px;
    background-color: #fff;
    .title{
      font-size: 40px;
      color: #252631;
      text-align: center;
    }
    .subtitle{
      font-size: 28px;
      color: #595961;
      text-align: center;
      margin: 10px 0 60px;
    }
    .van-cell{
      font-size: 24px;
      color: #252631;
      border: 1Px solid #BFBFBF;
      margin-bottom: 16px;
      padding: 8px 22px 6px;
      &.van-field--error{
        border: 1Px solid #ee0a24;
      }
      &::after{
        border-bottom: none;
      }
    }
    .submit-btn{
      background-color: #CEB287;
      color: #FFF;
      height: 72px;
      font-size: 24px;
      border: none;
      margin-top: 80px;
    }
  }
}
</style>