<template>
  <div class="saas">
    <pageTitle title="供应链SaaS服务"></pageTitle>
    <TabSwitch type="supplyChain" :tabs="tabs" :noSwiper='true'>
        <template slot="factory">
          <div class="product-main">
            <productItem
              type="saas"
              title="供应链协同"
              summary="供应链协同系统,打通上下游线上贸易采购全链路"
              link='supplychain'
            ></productItem>
            <productItem
              type="credit"
              title="赊销系统"
              summary="企业自主赊销业务线上化,精细化管理系统"
              link='sale'
            ></productItem>
            <productItem
              type="contract"
              title="智能合同"
              summary="电子合同智能存证,合同模板、签约、存证全流程线上化管理"
              link='contract'
            ></productItem>
            <productItem
              type="manage"
              title="项目管理"
              summary="项目全链路线上化协同、管理服务，打通断点、提升管理效率"
              link='projectManagement'
            ></productItem>
          </div>
        </template>
        <template slot="company">
          <div class="product-main">
            <productItem
              type="sale"
              title="销售工具"
              summary="自定义在线营销服务，全渠道灵活拓客，赋能客户增长"
              link='salesTools'
            ></productItem>
            <productItem
              type="erp"
              title="ERP"
              summary="商品、库存、订单系统化管理服务，业务在线管理"
              link='erp'
            ></productItem>
            <productItem
              type="crm"
              title="CRM"
              summary="多渠道客户统筹管理，在线协同跟进客户，赋能客户转化"
              link='crm'
            ></productItem>
            <productItem
              type="bidder"
              title="招投标管理"
              summary="全国招投标信息实时查询服务，即时订阅推送，把握销售商机"
              link='bidding'
            ></productItem>
          </div>
        </template>
    </TabSwitch>
  </div>
</template>

<script>
import PageTitle from './pageTitle'
import TabSwitch from './tabSwitch'
import ProductItem from './productItem'
export default {
  components: {
      PageTitle,
      TabSwitch,
      ProductItem
  },
  data() {
    return {
        tabs: [
            {
                name: '品牌厂家',
                key: 'factory'
            },
            {
                name: '小微企业',
                key: 'company'
            },
        ]
    }
  },
  mounted() {
    
  },
  destroyed() {
    
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins.scss";
.saas {
  position: relative;
  padding-bottom: 40px;
}
.product-main {
  display: flex;
  justify-content: space-between;
  padding: 0 37px;
  flex-wrap: wrap;
}
</style>