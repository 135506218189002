<template>
  <div class="news-detail">
    <div class="news-cnt">
      <Skeleton :loading="loading" :row="3" title></Skeleton>
      <Skeleton :loading="loading" :row="3" title></Skeleton>
      <div class="title">{{ info.title }}</div>
      <div class="date">{{ info.publishTime && info.publishTime.slice(0, 10) }}</div>
      <div class="content" v-html="info.mainBody"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { Skeleton } from 'vant';
import Router from '../../../router'
import http from '@/utils/request'
export default {
  components: {
    Skeleton
  },
  setup() {
    const id = ref('')
    const loading = ref(true)
    const info = ref({})
    onMounted(async () => {
      id.value = Router.currentRoute.params.id
      const res = await http.get('/oc/news/getById', { params: { id: id.value }})
      loading.value =false
      if (res.code === '0') {
        info.value = res.data
      }
    })
    return {
      id,
      info,
      loading
    }
  }
}
</script>

<style lang="scss">
.news-detail{
  background-color: #F5F5F7;
  padding: 60px 38px 220px;
  .news-cnt{
    background-color: #fff;
    padding: 48px 30px 90px 26px;
    box-shadow: 6px 10px 13px 0px #E6E8EB;
    .van-skeleton{
      padding-top: 24px;
    }
    .title{
      font-size: 36px;
      line-height: 48px;
    }
    .date{
      font-size: 20px;
      color: #2E2F33;
      margin: 30px 0 70px;
    }
    .content{
      font-size: 24px;
      line-height: 32px;
      img{
        width: 618px;
        margin: 28px 0 60px;
      }
    }
  }
}
</style>