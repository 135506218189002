<template>
  <div class="news-page">
    <div class="top-about">最新动态<br/>NEWS</div>
    <List v-model="loading" :finished="finished" @load="getNewsList()" class="list">
      <div v-for='item in newsList' :key='item._id' class="item" @click="toDetail(item)">
        <div class="img" :style="{backgroundImage: `url(${item.coverUrl})`}"/>
        <div class="info">{{ item.title }}</div>
        <div class="bottom">
          <div class="date">
            <div>{{ item.month }}</div><div class='year'>{{ item.year }}</div>
          </div>
          <div class="arrows">
            <Icon name="arrow" />
          </div>
        </div>
      </div>
    </List>
    <div v-if="(!loading) && newsList.length === 0" class="empty">暂无数据</div>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { Icon, List } from 'vant';
import Router from '../../router'
import http from '@/utils/request'
export default {
  components: {
    Icon, List
  },
  setup() {
    const loading = ref(true)
    const finished = ref(false)
    const pageNo = ref(0)
    const newsList = ref([])
    // 查询
    const getNewsList = async () => {
      if (finished.value) { return }
      pageNo.value = pageNo.value + 1
      loading.value = true
      const params = { pageSize: 10, pageNo: pageNo.value, type: 1 }
      const res = await http.get('/oc/news/page', { params })
      loading.value = false
      if (res.code === '0') {
        res.data.pagedRecords.map(item => {
          item.year = item.publishTime.slice(0, 4)
          item.month = item.publishTime.slice(5, 10)
        })
        if (pageNo.value > 1) {
          newsList.value = newsList.value.concat(...res.data.pagedRecords)
        } else {
          newsList.value = res.data.pagedRecords
        }
        if (res.data.totalCount < pageNo.value * 10) {
          finished.value = true
        }
      }
    }

    const toDetail = (item) => {
      Router.push({path: `/news/detail/${item.id}`})
    }

    onMounted(() => {
      getNewsList()
    })

    return {
      loading,
      finished,
      getNewsList,
      toDetail,
      newsList
    }
  }
}
</script>

<style lang="scss">
.news-page{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .top-about{
    height: 400px;
    background: url('../../assets/images/news/top.png');
    background-size: 100% auto;
    color: #FFF;
    font-size: 36px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 64px;
  }
  .list{
    padding: 30px;
    width: 750px;
    display: flex;
    flex-wrap: wrap;
    .item{
      width: 327px;
      margin: 9px;
      box-shadow: 6px 10px 13px 0px #E6E8EB;
      .img{
        background-size:cover;
        background-color: #EEE;
        background-position: center center;
        width: 327px;
        height: 269px;
      }
      .info{
        padding: 24px 24px 0;
        height: 90px;
        line-height: 32px;
        margin-bottom: 18px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .bottom{
        color: #2E2F33;
        padding: 0 26px 26px;
        display: flex;
        justify-content: space-between;
        font-size: 28px;
        align-items: center;
        .year{
          font-size: 16px;
        }
        .arrows{
          background-color: #CEB287;
          width: 36px;
          height: 36px;
          color: #FFF;
          text-align: center;
          padding-top: 4px;
        }
      }
    }
  }
  .van-list__loading{
    flex: 1 0 auto;
  }
  .empty{
    text-align: center;
    padding-bottom: 60px;
    font-size: 24px;
  }
}
</style>