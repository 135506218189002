<template>
  <div class="download-page">
    <div class="version" v-if="publishType === '1'">{{appChineseName}}正式版</div>
    <div class="version" v-else-if="publishType === '2'">{{appChineseName}}演示版</div>
    <a class="btn" :href='link'>立即下载</a>
    <a class="iosGuide" href='/IOSgulide' v-if="publishType === '2' && isIOS">ios首次安装演示版请查看>></a>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  components: {
  },
  data() {
    return {
      link: 'javascript:;',
      publishType: '1',
      isIOS: false,
      appName: '',
      appChineseName: '',
      appNames: {
        qjdkdt: '仟金顶',
        qjdSaas: '订货大师'
      },
      storeUrl: {
        qjdkdt: 'https://apps.apple.com/cn/app/%E4%BB%9F%E9%87%91%E9%A1%B6/id1498867269',
        qjdSaas: 'https://apps.apple.com/cn/app/%E8%AE%A2%E8%B4%A7%E5%A4%A7%E5%B8%88/id1599234717',
      }
    }
  },
  async mounted() {
    document.title = '【官网-APP下载二维码】'
    const { name: routerName } = this.$route
    const UA = window.navigator.userAgent.toLowerCase()
    const isIOS = (UA && /iphone|ipad|ipod|ios/.test(UA))
    this.publishType = routerName === 'downloadYs' ? '2' : '1'
    this.isIOS = isIOS
    this.appName = this.$route.query.appName || 'qjdkdt'
    this.appChineseName = this.appNames[this.appName]
    const params = {
      type: isIOS ? 2 : 1,
      publishType: this.publishType,
      appName: this.appName
    }
    const res = await axios.get(`https://${process.env.VUE_APP_WEBTOOLS_HOST}/api/getVersion`, { params })
    if (res.data && res.data.code === '0') {
      if (isIOS && !res.data.data[0].url) {
        this.link = this.storeUrl[this.appName]
      } else {
        this.link = res.data.data[0].url
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.download-page{
  height: 100vh;
  background: url('../../assets/images/about/appDownload.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #000d3d;
  .version {
    position: fixed;
    bottom: 250px;
    left: 260px;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #fff;
  }
  .iosGuide {
    position: fixed;
    bottom: 10px;
    left: 200px;
    text-align: center;
    font-size: 30px;
    color: #fff;
  }
  .btn{
    display: block;
    background-color: #ee7b2e;
    color: #FFF;
    font-size: 32px;
    text-align: center;
    line-height: 36px;
    position: fixed;
    bottom: 80px;
    left: 205px;
    width: 360px;
    padding: 34px 0;
    border-radius: 50px;
  }
}
</style>