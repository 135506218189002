<template>
  <div class="saas">
    <pageTitle title="数据SaaS服务"></pageTitle>
    <TabSwitch type="bidData" :tabs="tabs" effect="fade" :noSwiper='false' :hideNoActiveTab='false'>
        <template slot="factory">
          <div class="product-main">
            <TabSwitch :showBtn="false" type="bidDataFactory" :tabs="factory" :scrollbar="true" effect="slide" :swiperOption='{
                loop:false,
                slidesOffsetBefore: 12,
                slidesOffsetAfter: 6,
                freeMode: true,
                slidesPerView: 2,
            }'>
              <template slot="jxsfc">
                <productItem
                  :inline="true"
                  type="jxsfc"
                  title="经销商分层"
                  summary="经销商多维智能评估模型，立体分层锁定优质下游企业"
                  link='layeringDealer'
                ></productItem>
              </template>
              <template slot="kfspg">
                <productItem
                  :inline="true"
                  type="kfspg"
                  title="客户评估"
                  summary="一站式数据分析评估服务，识别建筑工程商、项目资质"
                  link='developersAssess'
                ></productItem>
              </template>
              <template slot="fxyq">
                <productItem
                  :inline="true"
                  type="fxyq"
                  title="风险引擎"
                  summary="企业贸易伙伴风险、舆情智能监控服务，贸易风险即时预警"
                  link='riskEngine'
                ></productItem>
              </template>
            </TabSwitch>
          </div>
        </template>
        <template slot="company">
          <div class="product-main">
            <TabSwitch :showBtn="false" type="bidDataCompany" :tabs="company" :scrollbar="true" effect="slide" :swiperOption='{
                loop:false,
                slidesOffsetBefore: 12,
                slidesOffsetAfter: 6,
                freeMode: true,
                slidesPerView: 2,
            }'>
              <template slot="xmpg">
                <productItem
                  :inline="true"
                  type="xmpg"
                  title="项目评估"
                  summary="项目风险深度评估服务，专家评估，精准识别项目风险"
                  link='projectAppraisal'
                ></productItem>
              </template>
              <template slot="qjy">
                <productItem
                  :inline="true"
                  type="qjy"
                  title="仟金眼"
                  summary="企业商业报告查询服务，深度剖析企业基本资质，锁定优质合作"
                  link='eye'
                ></productItem>
              </template>
              <template slot="sjkb">
                <productItem
                  :inline="true"
                  type="sjkb"
                  title="数据看板"
                  summary="数据可视化看板，赋能企业经营决策"
                  link='dataCockpit'
                ></productItem>
              </template>
            </TabSwitch>
          </div>
        </template>
    </TabSwitch>
  </div>
</template>

<script>
import PageTitle from './pageTitle'
import TabSwitch from './tabSwitch'
import ProductItem from './productItem'
export default {
  components: {
      PageTitle,
      TabSwitch,
      ProductItem
  },
  data() {
    return {
        tabs: [
            {
                name: '品牌厂家',
                key: 'factory'
            },
            {
                name: '小微企业',
                key: 'company'
            },
        ],
        factory: [
          {
            name: '经销商分层',
            key: 'jxsfc'
          },
          {
            name: '开发商评估',
            key: 'kfspg'
          },
          {
            name: '风险引擎',
            key: 'fxyq'
          },
        ],
        company: [
          {
            name: '项目评估',
            key: 'xmpg'
          },
          {
            name: '仟金眼',
            key: 'qjy'
          },
          {
            name: '数据看板',
            key: 'sjkb'
          },
        ]
    }
  },
  mounted() {
    
  },
  destroyed() {
    
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins.scss";
.saas {
  background-color: #fff;
  padding-top: 1Px;
  padding-bottom: 40px;
  position: relative;
  ::v-deep .tab-btn{
    background-color: #F5F6F7;
    margin-bottom: 30px;
  }
  ::v-deep .swiper-container{
    padding-top: 15px;
  }
}
</style>