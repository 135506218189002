<template>
	<div class="risk_box">
		<img class="risk_ig" :src='`${$assetsBaseUrl}/risk/ig1.jpg`' />
		<img class="risk_ig" :src='`${$assetsBaseUrl}/risk/ig2.jpg`' />
		<a :href='link'>
			<img class="risk_down" src="../../assets/images/risk/down.png"/>
		</a>
	</div>
</template>
<script>
import { ref } from "@vue/composition-api"
import axios from 'axios'

export default {
	setup() {
		const link = ref()

		const init = async () => {
			try {
				const UA = window.navigator.userAgent.toLowerCase()
				const isIOS = (UA && /iphone|ipad|ipod|ios/.test(UA))
				const { data: { code, data } } = await axios.get(`https://${process.env.VUE_APP_WEBTOOLS_HOST}/api/getVersion`, { params: {
					type: isIOS ? 2 : 1,
					publishType: 1,
					appName: 'qjdRisk'
				} })
				if (code === '0') {
					if (isIOS) {
						link.value = 'https://apps.apple.com/cn/app/%E9%A3%8E%E9%99%A9%E4%B8%93%E5%AE%B6/id1618146565'
					} else {
						link.value = data?.[0]?.url
					}
				}
			} catch (error) {
				console.error(`error: ${error}`)
			}
			
		}

		init()

		return {
			link
		}
	}
}
</script>
<style lang="scss" scoped>
.risk_box {
	width: 100%;
	.risk_ig {
		display: block;
		width: 100%;
	}
	.risk_down {
		width: 90%;
		position: fixed;
		bottom: 2vh;
		left: 5%;
	}
}
</style>
