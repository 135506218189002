<template>
  <div class="about-page">
    <div class="top-about">赋能行业价值链企业 推动供应链数字转型</div>
    <div class="conpony-info">
      <div class="title">公司简介</div>
      <div>仟金顶总部位于中国杭州，是由绿城中国、红杉资本投资的网筑集团旗下全资子公司，是科技驱动型供应链综合服务平台。</div>
    </div>
    <div class="mission">
      <div class="title">企业文化</div>
      <div class="list">
        <div class="item">
          <div class="item-title">企业愿景</div>
          <div>致力于打造中</div>
          <div>国领先的科技</div>
          <div>驱动型供应链</div>
          <div>综合服务平台</div>
        </div>
        <div class="item">
          <div class="item-title">企业使命</div>
          <div>赋能行业价值链企业</div>
          <div>推动供应链数字转型</div>
        </div>
        <div class="item">
          <div class="item-title">价值观</div>
          <div>客户第一</div>
          <div>诚实守信</div>
        </div>
      </div>
    </div>
    <!-- <div class="history">
      <div class="title">发展历程</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img class="his-img left" src="../../assets/images/about/history-1.png">
        </swiper-slide>
        <swiper-slide>
          <img class="his-img right" src="../../assets/images/about/history-2.png">
        </swiper-slide>
        <swiper-slide>
          <img class="his-img left" src="../../assets/images/about/history-3.png">
        </swiper-slide>
        <div class="about-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>-->
    <div class="honor">
      <div class="title">企业荣誉</div>
      <div class="desc">
        仟金顶网络科技有限公司及其子公司浙江网筑信息技术服务有限公司深耕供应链服务，
        赋能产业链企业，凭借前沿的技术和专业的服务，获得政府、社会机构及合作伙伴的认可。
      </div>
      <div class="hornor-list">
        <div class="honor-left">
          <div class="item">国家高新技术企业</div>
          <div class="item">省级工业互联网平台</div>
          <div class="item">浙江省科技型企业</div>
          <div class="item">中国“重质量、守诚信”示范企业</div>
          <div class="item">中国市场信用AAA级信用企业</div>
          <div class="item">获得“优秀瞪羚企业”认定</div>
          <div class="item">中国B2B建材供应链领域杰出贡献奖</div>
          <div class="item">美的中央空调供应链服务特别贡献奖</div>
          <div class="item">东芝空调优秀合作伙伴</div>
          <div class="item">通力中国最佳贡献奖</div>
          <div class="item">三菱重工海尔优秀合作伙伴</div>
          <div class="item">亚士创能最佳合作伙伴</div>
          <div class="item">天加环境优秀合作伙伴</div>
          <div class="item">固克最佳战略合作伙伴</div>
          <div class="item">铃鹿年度最佳合作伙伴</div>
          <div class="item">亚细亚瓷砖年度优秀合作伙伴</div>
          <div class="item">顿汉布什年度优秀合作伙伴</div>
          <div class="yingyu">Company Achievements</div>
        </div>
        <img src="../../assets/images/about/honor.png" class="right-pic" />
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    swiper, swiperSlide
  },
  data() {
    return {
      swiperOptions: {
        scrollbar: {
          el: '.about-scrollbar',
        },
        slidesOffsetBefore: 20,
        freeMode: true,
      }
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.about-page {
  .top-about {
    height: 400px;
    background: url("../../assets/images/about/top.png");
    background-size: 100% auto;
    color: #fff;
    font-size: 36px;
    line-height: 400px;
    text-align: center;
  }
  .title {
    color: #252631;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    padding-top: 56px;
    padding-bottom: 40px;
    &::after {
      content: "";
      display: block;
      width: 62px;
      height: 6px;
      background-color: #e29a27;
      margin: 0 auto;
      margin-top: 17px;
    }
  }
  .conpony-info {
    background-color: #fff;
    padding: 0 38px 61px;
    font-size: 24px;
    line-height: 40px;
    span {
      color: #ceb287;
    }
  }
  .mission {
    height: 480px;
    background: url("../../assets/images/about/mission-bg.png");
    background-size: 100% auto;
    padding: 0 30px 42px;
    display: flex;
    flex-direction: column;
    .title {
      color: #fff;
      padding-bottom: 30px;
      &::after {
        content: none;
      }
    }
    .list {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      .item {
        flex: 1;
        background-color: #fff;
        padding: 50px 10px 0px 36px;
        margin: 8px;
        line-height: 32px;
        .item-title {
          position: relative;
          font-size: 28px;
          line-height: 30px;
          padding-left: 14px;
          margin-bottom: 40px;
          &::after {
            position: absolute;
            content: "";
            display: block;
            width: 4px;
            height: 20px;
            background-color: #e29a27;
            top: 6px;
            left: 0px;
          }
        }
      }
    }
  }
  .history {
    .his-img {
      width: 750px;
    }
    .about-scrollbar {
      height: 8px;
      width: 100px;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 40px;
      background-color: #eaebee;
      border-radius: 8px;
      .swiper-scrollbar-drag {
        background-color: #ceb287;
      }
    }
  }
  .honor {
    background-color: #fff;
    .desc {
      line-height: 40px;
      padding: 0 38px 38px;
      font-size: 24px;
      text-align: justify;
    }
    .hornor-list {
      display: flex;
      padding: 50px 38px 60px 38px;
      .honor-left {
        flex: 1;
        .item {
          padding-left: 34px;
          padding-right: 60px;
          position: relative;
          line-height: 30px;
          font-size: 24px;
          color: #5c5f66;
          margin-bottom: 16px;
          &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: 0;
            width: 6px;
            height: 6px;
            background-color: #ddbb87;
          }
        }
        .yingyu {
          margin-top: 40px;
          font-size: 22px;
          font-weight: bold;
          padding-left: 4px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 36px;
            left: 4px;
            width: 68px;
            height: 3px;
            background-color: #ddbb87;
          }
        }
      }
      .right-pic {
        display: block;
        width: 196px;
        height: 840px;
      }
    }
  }
}
</style>