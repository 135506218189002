<template>
<div class="tab">
    <div class="tab-btn" v-show="showBtn">
        <div class="panel" ref="panel" :style="{width: panelWidth+'%', left: panelLeft+'px'}"></div>
        <div class="tab-item" v-for="(item, index) in tabs" :key="item.key" :class="currentKey === item.key ? 'current' : ''" @click="changeTab(index)">
            <span class="text">{{item.name}}</span>
        </div>
    </div>
    <swiper
        :ref="`mySwiper${type}`"
        :activeIndex='currentIndex'
        :options="swiperOptions"
        @slideChange="onSlideChange"
        v-if='tabs.length > 0'
    >
        <swiper-slide :class="`${pagination ? 'page' : ''} ${noSwiper ? 'swiper-no-swiping' : ''}`" v-for="(item, key) in tabs" :key='key'>
            <slot :name="item.key" v-if='hideNoActiveTab || item.key === currentKey'></slot>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" v-if="pagination"></div>
        <div class="item-scrollbar" slot="scrollbar" v-if="scrollbar"></div>
    </swiper>
</div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
// 1. 存储swiper元素,避免重复获取
const c_swiper = document.getElementsByClassName("swiper")
export default {
    props: {
        type: {
            type: String
        },
        tabs: {
            type: Array,
            default: () => []
        },
        pagination: {
            type: Boolean,
            default: false
        },
        scrollbar: {
            type: Boolean,
            default: false
        },
        noSwiper: {
            type: Boolean,
            default: false
        },
        hideNoActiveTab: {
            type: Boolean,
            default: true
        },
        showBtn: {
            type: Boolean,
            default: true
        },
        effect: {
            type: String,
            default: 'slide'
        },
        swiperOption: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        swiper,
        swiperSlide
    },
    computed: {
        panelWidth() {
            return 96 / this.tabs.length
        },
    },
    data() {
        return {
            currentIndex: 0,
            currentKey: this.tabs[0].key,
            panelLeft: 3,
            swiperOptions: {
                pagination: {
                    el: this.pagination ? '.swiper-pagination' : '',    //与slot="pagination"处 class 一致
                    bulletClass: 'product-bullet',    //分页器默认样式
                    bulletActiveClass: 'product-bullet-active',  //分页器选中样式
                    clickable: false,           //轮播按钮支持点击
                },
                scrollbar: {
                    el: this.scrollbar ? '.item-scrollbar' : '',
                },
                effect: this.effect,
                loop: false,
                noSwiping: true,
                observer:true,
                observeParents:true,
                ...this.swiperOption
            },
        }
    },
    methods: {
        changeTab(index) {
            this.$refs[`mySwiper${this.type}`].swiper.slideTo(index)
        },
        onSlideChange() {
            const { realIndex, activeIndex} = this.$refs[`mySwiper${this.type}`].swiper
            const currentItem =  this.tabs.filter((item, i) => {
                return i === realIndex
            })[0]
            this.currentKey = currentItem.key
            this.currentIndex = realIndex
            this.panelLeft = this.$refs.panel.clientWidth * activeIndex + 3
            this.changeTab(activeIndex)
        }
    },
    mounted() {
    },
}
</script>
<style lang="scss">
.product-bullet {
    width: 16px;
    height: 16px;
    background: #dddddd;
    display: inline-block;
    margin: 0 5px -10px 5px;
    border-radius: 8px;
}
.product-bullet-active{
    background: #CEB287;
}
.item-scrollbar{
    height: 8px;
    width: 100px;
    margin: 50px auto 10px;
    background-color: #EAEBEE;
    border-radius: 8px;
    .swiper-scrollbar-drag{
        background-color: #CEB287;
    }
}
</style>
<style lang="scss" scoped>
.tab-btn{
    background: #ffffff;
    padding: 5px;
    border-radius: 39px;
    margin: 0 auto 45px auto;
    display: inline-flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    height: 80px;
    .tab-item {
        color: #A9AFB8;
        padding: 15px 25px;
        border-radius: 39px;
        font-size: 28px;
    }
    .tab-item.current {
        color: #ffffff;
    }
    .panel {
        background: #CEB287;
        height: 69px;
        border-radius: 39px;
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: -1;
        transition: 0.4s;
    }
}
.page{
    padding-bottom: 50px;
}
</style>
