<template>
  <div class="solution">
    <pageTitle title="企业解决方案" color='#ffffff'></pageTitle>
    <div class="detail"></div>
  </div>
</template>

<script>
import PageTitle from './pageTitle'
export default {
  components: {
    PageTitle
  },
  data() {
    return {
     
    }
  },
  mounted() {
    
  },
  destroyed() {
    
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins.scss";
.solution {
  background: url('~@/assets/images/home/solution-bg.png') no-repeat center top;
  background-size: cover;
  width: 100%;
  padding: 47px 24px 64px 24px;
  .detail {
    background: url('~@/assets/images/home/solution.png') no-repeat center top;
    background-size: contain;
    width: 100%;
    height: 659px;
  }
}
</style>