<template>
  <div class="main">
    <div class="title" :style="{color: color}">
      {{title}}
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    color: {
      type: String,
      default: '#252631'
    }
  },
  components: {

  },
  data() {
    return {
     
    }
  },
  mounted() {
    
  },
  destroyed() {
    
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    margin: 47px 0 17px 0;
}
.line {
    width:62px;
    height: 6px;
    background: #E29A27;
    margin: 0 auto 27px auto;
}
</style>