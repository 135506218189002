<template>
  <div class="partner">
    <pageTitle title="业务数据概览"></pageTitle>
    <div class="detail">
      <div class="p-data-main">
        <div class="p-data-item">
          <div class="p-data-num">
            <CountTo :autoplay="autoplay" :duration="duration" :endVal="300" :startVal="0" ref="countTo1" separator>300</CountTo>
            <span class="p-plus">+</span>
          </div>
          <div class="p-data-title">合作品牌数</div>
        </div>
        <div class="p-data-item">
          <div class="p-data-num">
            <CountTo :autoplay="autoplay" :duration="duration" :endVal="100000" :startVal="0" ref="countTo2" separator>100000</CountTo>
            <span class="p-plus">+</span>
          </div>
          <div class="p-data-title">服务用户数</div>
        </div>
        <div class="p-data-item">
          <div class="p-data-num">
            <CountTo :autoplay="autoplay" :duration="duration" :endVal="130" :startVal="0" ref="countTo3" separator>130</CountTo>
            <span class="p-plus">%</span>
          </div>
          <div class="p-data-title">收入留存率</div>
        </div>
        <div class="p-data-item">
          <div class="p-data-num">
            <CountTo :autoplay="autoplay" :duration="duration" :endVal="90" :startVal="0" ref="countTo4" separator>90</CountTo>
            <span class="p-plus">%</span>
          </div>
          <div class="p-data-title">用户留存率</div>
        </div>
      </div>
      <div class="p-map"></div>
    </div>
  </div>
</template>

<script>
import PageTitle from './pageTitle'
import CountTo from 'vue-count-to'
export default {
  components: {
    PageTitle,
    CountTo
  },
  data() {
    return {
      duration: 2000,
      autoplay: false,
      flag: true
    }
  },
  methods: {
    start() {
      if (!this.flag) return
      for (let key in this.$refs) {
        this.$refs[key] && this.$refs[key].start()
      }
      this.flag = false
    }
  },
  mounted() {
  },
  destroyed() {
    
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins.scss";
.partner {
  background: #ffffff;
  padding:  20px 33px 47px 33px;
  .detail {
    .p-data-main {
      display: flex;
      justify-content: space-between;
      .p-data-item {
        margin-right: 10px;
        min-width: 80px;
        .p-data-num {
          color: #252631;
          font-size: 40px;
          font-weight: bold;
          .p-plus {
            margin-left: -10px;
          }
          .p-unit {
            font-size: 32px;
            margin-left: -5px;
            .p-plus {
              margin-left: 0;
            }
          }
        }
        .p-data-title {
          color: #A9AFB8;
          font-size: 24px;
        }
      }
      .p-data-item:last-child {
        margin-right: 0;
      }
    }
    .p-map {
      width: 672px;
      height: 519px;
      background: url('~@/assets/images/home/map.png') no-repeat;
      background-size: contain;
      margin: 60px auto 0 auto;
    }
  }
}

</style>