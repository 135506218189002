<template>
  <div class="partner">
    <pageTitle title="合作客户"></pageTitle>
    <swiper
      :ref="`mySwiper`"
      :activeIndex='currentIndex'
      :options="swiperOptions"
      class="partner-slider"
  >
      <swiper-slide>
          <img class="logo-item" src="../../assets/images/home/partners-1.png" />
      </swiper-slide>
      <swiper-slide>
          <img class="logo-item" src="../../assets/images/home/partners-2.png" />
      </swiper-slide>
      <swiper-slide>
          <img class="logo-item" src="../../assets/images/home/partners-3.png" />
      </swiper-slide>
      <swiper-slide>
          <img class="logo-item" src="../../assets/images/home/partners-4.png" />
      </swiper-slide>
      <div class="home-partner-scrollbar" slot="scrollbar"></div>
    </swiper>
  </div>
</template>

<script>
import PageTitle from './pageTitle'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    PageTitle,
    swiper,
    swiperSlide
  },
  data() {
    return {
      currentIndex: 0,
      swiperOptions: {
        scrollbar: {
          el: '.home-partner-scrollbar',
        },
        slidesOffsetAfter: 20,
        freeMode : true,
        slidesPerView: 1.15
      },
    }
  },
  mounted() {
    
  },
  destroyed() {
    
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
  .home-partner-scrollbar{
    height: 8px;
    width: 100px;
    margin: 0 auto;
    margin-top: 40px;
    background-color: #EAEBEE;
    border-radius: 8px;
    .swiper-scrollbar-drag{
      background-color: #CEB287;
    }
  }
</style>
<style lang="scss" scoped>
@import "~@/assets/style/mixins.scss";
.partner {
  padding-bottom: 20px;
  .partner-slider {
    padding-bottom: 20px;
    .logo-item {
      width: 96%;
    }
    .logo-item:first-child {
      margin-left: 37px;
    }
  }
}
</style>