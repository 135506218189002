<template>
  <div class="advantage">
    <pageTitle title="核心优势"></pageTitle>
    <div class="detail">
      <productItem
        type="industry"
        title="行业经验"
        summary="深耕泛建材领域,细分行业TOP品牌全覆盖,细分行业的综合深度服务能力"
      ></productItem>
      <productItem
        type="technology"
        title="技术能力"
        summary="近200人技术团队,先进技术紧贴行业特性,建立细分行业的技术应用标准"
      ></productItem>
      <productItem
        type="data"
        title="数据能力"
        summary="拥有国内大数据领域的稀缺型人才高质量数据沉淀整合行业数据,独特的算法能力,挖掘行业特征"
      ></productItem>
      <productItem
        type="risk"
        title="风控能力"
        summary="行业内专业风控团队专业风险评估模型独有风控模型随时把握合作风险"
      ></productItem>
    </div>
  </div>
</template>

<script>
import PageTitle from './pageTitle'
import ProductItem from './productItem'
export default {
  components: {
    PageTitle,
    ProductItem
  },
  data() {
    return {
     
    }
  },
  mounted() {
    
  },
  destroyed() {
    
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins.scss";
.advantage {
  .detail {
    display: flex;
    justify-content: space-between;
    padding: 0 37px;
    flex-wrap: wrap;
  }
}
</style>