var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"saas"},[_c('pageTitle',{attrs:{"title":"数据SaaS服务"}}),_c('TabSwitch',{attrs:{"type":"bidData","tabs":_vm.tabs,"effect":"fade","noSwiper":false,"hideNoActiveTab":false}},[_c('template',{slot:"factory"},[_c('div',{staticClass:"product-main"},[_c('TabSwitch',{attrs:{"showBtn":false,"type":"bidDataFactory","tabs":_vm.factory,"scrollbar":true,"effect":"slide","swiperOption":{
              loop:false,
              slidesOffsetBefore: 12,
              slidesOffsetAfter: 6,
              freeMode: true,
              slidesPerView: 2,
          }}},[_c('template',{slot:"jxsfc"},[_c('productItem',{attrs:{"inline":true,"type":"jxsfc","title":"经销商分层","summary":"经销商多维智能评估模型，立体分层锁定优质下游企业","link":"layeringDealer"}})],1),_c('template',{slot:"kfspg"},[_c('productItem',{attrs:{"inline":true,"type":"kfspg","title":"客户评估","summary":"一站式数据分析评估服务，识别建筑工程商、项目资质","link":"developersAssess"}})],1),_c('template',{slot:"fxyq"},[_c('productItem',{attrs:{"inline":true,"type":"fxyq","title":"风险引擎","summary":"企业贸易伙伴风险、舆情智能监控服务，贸易风险即时预警","link":"riskEngine"}})],1)],2)],1)]),_c('template',{slot:"company"},[_c('div',{staticClass:"product-main"},[_c('TabSwitch',{attrs:{"showBtn":false,"type":"bidDataCompany","tabs":_vm.company,"scrollbar":true,"effect":"slide","swiperOption":{
              loop:false,
              slidesOffsetBefore: 12,
              slidesOffsetAfter: 6,
              freeMode: true,
              slidesPerView: 2,
          }}},[_c('template',{slot:"xmpg"},[_c('productItem',{attrs:{"inline":true,"type":"xmpg","title":"项目评估","summary":"项目风险深度评估服务，专家评估，精准识别项目风险","link":"projectAppraisal"}})],1),_c('template',{slot:"qjy"},[_c('productItem',{attrs:{"inline":true,"type":"qjy","title":"仟金眼","summary":"企业商业报告查询服务，深度剖析企业基本资质，锁定优质合作","link":"eye"}})],1),_c('template',{slot:"sjkb"},[_c('productItem',{attrs:{"inline":true,"type":"sjkb","title":"数据看板","summary":"数据可视化看板，赋能企业经营决策","link":"dataCockpit"}})],1)],2)],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }