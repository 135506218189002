<template>
  <div :class="`product ${inline? 'inline' : ''}`">
    <div :class="`pic ${type}`"></div>
    <div class="title">{{title}}</div>
    <div class="line"></div>
    <div class="summary">{{summary}}</div>
    <div class="more" v-show='link' @click="toPage">了解详情 >>></div>
  </div>
</template>

<script>
export default {
  props: {
      title: {
          type: String
      },
      summary: {
          type: String
      },
      type: {
          type: String
      },
      inline: {
          type: Boolean,
          default: false
      },
      link: {
          type: String,
      }
  },
  data() {
    return {

    }
  },
  mounted() {
    
  },
  destroyed() {
    
  },
  methods: {
    toPage() {
      this.$router.push({name: this.link})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins.scss";
.product {
    background: #fff;
    width: 48.5%;
    box-shadow: 0px 8px 28px 0px #F1F1F1;
    text-align: center;
    padding: 42px 20px 40px 20px;
    margin-bottom: 24px;
    height: 444px;
    display: flex;
    flex-direction: column;
    .title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .line {
        width: 40px;
        height: 3px;
        background: #D8D8D8;
        margin: 0 auto 12px auto;
    }
    .summary {
        color: #A9AFB8;
        font-size: 24px;
        line-height: 28px;
    }
    .more {
        color: #CEB287;
        flex: 1 0 auto;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .pic {
        width: 162px;
        height: 162px;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 auto 13px auto;
    }
    .pic.saas {
        background-image: url('~@/assets/images/home/saas.png');
    }
    .pic.credit {
        background-image: url('~@/assets/images/home/credit.png');
    }
    .pic.contract {
        background-image: url('~@/assets/images/home/contract.png');
    }
    .pic.manage {
        background-image: url('~@/assets/images/home/manage.png');
    }
    .pic.sale {
        background-image: url('~@/assets/images/home/sale.png');
    }
    .pic.erp {
        background-image: url('~@/assets/images/home/erp.png');
    }
    .pic.crm {
        background-image: url('~@/assets/images/home/crm.png');
    }
    .pic.bidder {
        background-image: url('~@/assets/images/home/bidder.png');
    }
    .pic.jxsfc {
        background-image: url('~@/assets/images/home/jxsfc.png');
    }
    .pic.kfspg {
        background-image: url('~@/assets/images/home/kfspg.png');
    }
    .pic.fxyq {
        background-image: url('~@/assets/images/home/fxyq.png');
    }
    .pic.xmpg {
        background-image: url('~@/assets/images/home/xmpg.png');
    }
    .pic.qjy {
        background-image: url('~@/assets/images/home/qjy.png');
    }
    .pic.sjkb {
        background-image: url('~@/assets/images/home/sjkb.png');
    }
    .pic.industry {
        background-image: url('~@/assets/images/home/industry.png');
    }
    .pic.technology {
        background-image: url('~@/assets/images/home/technology.png');
    }
    .pic.data {
        background-image: url('~@/assets/images/home/data.png');
    }
    .pic.risk {
        background-image: url('~@/assets/images/home/risk.png');
    }
}
.product.inline {
    width: 90%;
    margin: 0 12px;
}
</style>