<template>
  <div class="partner-product">
    <TopBanner/>
    <div class="brand">
      <div class="pdc-title">合作品牌</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img src="../../assets/images/partner/brand-1.png">
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/images/partner/brand-2.png">
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/images/partner/brand-3.png">
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/images/partner/brand-4.png">
        </swiper-slide>
        <div class="partner-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <div class="mechanism">
      <div class="pdc-title">合作机构</div>
      <img src="../../assets/images/partner/banks.png">
    </div>
    <div class="process">
      <div class="pdc-title">合作流程</div>
      <swiper ref="mySwiper" :options="swiperOptions2">
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../assets/images/partner/process-1.png"/>
            <div>1.意向登记</div>
            <div class="arrows">
              <Icon name="arrow" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../assets/images/partner/process-2.png"/>
            <div>2.需求沟通</div>
            <div class="arrows">
              <Icon name="arrow" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../assets/images/partner/process-3.png"/>
            <div>3.方案制定</div>
            <div class="arrows">
              <Icon name="arrow" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../assets/images/partner/process-4.png"/>
            <div>4.签订合作协议</div>
            <div class="arrows">
              <Icon name="arrow" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../assets/images/partner/process-5.png"/>
            <div>5.开通服务</div>
            <div class="arrows">
              <Icon name="arrow" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <router-link to="/friend/cooperate" class="btn-area">申请合作</router-link>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import { Icon } from 'vant';
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, swiper, swiperSlide, SignButton, Icon
  },
  data() {
    return {
      swiperOptions: {
        scrollbar: {
          el: '.partner-scrollbar',
        },
        slidesPerView: 1.6,
        slidesOffsetBefore: 20,
        freeMode : true,
      },
      swiperOptions2: {
        slidesPerView: 2.6,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        freeMode : true,
      }
    
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.partner-product{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .brand{
    img{
      display: block;
      width: 468px;
    }
    .partner-scrollbar{
      height: 8px;
      width: 100px;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 86px;
      background-color: #EAEBEE;
      border-radius: 8px;
      .swiper-scrollbar-drag{
        background-color: #CEB287;
      }
    }
  }
  .mechanism{
    background-color: #F5F5F7;
    text-align: center;
    padding-bottom: 60px;
    img{
      width: 673px;
    }
  }
  .process{
    padding-bottom: 64px;
    .swiper-slide:nth-child(1) .item{
      .arrows{
        display: none;
      }
    }
    .item{
      background-color: #F5F5F7;
      text-align: center;
      position: relative;
      border: 1Px solid #FFF;
      padding-bottom: 54px;
      .title-pic{
        display: block;
        margin: 0 auto;
        padding-top: 54px;
        margin-bottom: 50px;
        width: 132px;
      }
      .arrows{
        position: absolute;
        top: 94px;
        left: -24px;
        width: 46px;
        height: 46px;
        background-color: #fff;
        border-radius: 46px;
        color: #CACACA;
        line-height: 56px;
        padding-left: 4px;
      }
    }
    .btn-area{
      display: block;
      text-align: center;
      width: 160px;
      margin: 0 auto;
      margin-top: 60px;
      border: 1Px solid #BABABA;
      padding: 18px 0;
    }
  }
  .pdc-title{
    color: #252631;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    padding-top: 56px;
    padding-bottom: 40px;
    &::after{
      content: "";
      display: block;
      width: 62px;
      height: 6px;
      background-color: #E29A27;
      margin: 0 auto;
      margin-top: 17px;
    }
  }
}
</style>