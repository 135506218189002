<template>
  <div class="banner">
    <swiper
        ref="mySwiper" 
        :options="swiperOptions"
    >
        <swiper-slide class="banner-item" v-for="(item, key) in listData" :key='key'>
          <a :href="item.url" target="_blank">
            <img :src="item.src" alt="" />
          </a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    swiper,
    swiperSlide
  },
  computed: {
    
  },
  data() {
    return {
        swiperOptions: {
            pagination: {
                el: '.swiper-pagination',    //与slot="pagination"处 class 一致
                bulletClass: 'banner-bullet',    //分页器默认样式
                bulletActiveClass: 'banner-bullet-active',  //分页器选中样式
                clickable: true,           //轮播按钮支持点击
            },
            loop: true
        },
        listData: [
            {
                src: '/banner/banner-1.jpg',
                url: 'https://webtools.qjdchina.com/activity/jdb/m'
            }
        ]
    }
  },
  mounted() {
    
  },
  destroyed() {
    
  },
  methods: {
    
  }
}
</script>
<style lang="scss">
.banner-bullet {
    width: 28px;
    height: 6px;
    background: #9B9C9D;
    display: inline-block;
    margin: 0 5px 10px 5px;
}
.banner-bullet-active{
    background: #ffffff;
}
</style>
<style lang="scss" scoped>
.banner {
  display: flex;
  .banner-item {
    img {
      width: 100%;
    }
  }
  .swiper-pagination {
      display: flex;
      justify-content: flex-end;
      padding-right: 35px;
      margin-bottom: 10px;
  }
}
</style>